html, body {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
}

/*# sourceMappingURL=index.5347eeb9.css.map */
