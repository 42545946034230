html,
body {
	margin: 0;
	padding: 0;
}

img {
	height: auto;
	max-width: 100%;
}
